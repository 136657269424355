import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./logo.png";

import "styles/reset.css";

import { useAuth } from "AuthContext";
import "styles/custom.css";

const transform = (node) => {};

const BlogContext = React.createContext({ pages: [] });

const NavLink = ({ children, to, external = false }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
          textUnderlineOffset: 8,
          textDecorationThickness: 2,
          textDecorationColor: "#c83881",
        },
      }}
      onClick={() => (external ? (window.location.href = to) : navigate(to))}
    >
      {children}
    </Box>
  );
};

const drawerWidth = 240;
const navItems = [
  {
    label: "Stories",
    props: { to: "/posts", external: true },
  },
  {
    label: "Patient Hub",
    props: { to: "/hub/exercise-program", external: true },
  },
];

export const Navbar = () => {
  const navigate = useNavigate();

  const { user, logout } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const container: any = window.document.body;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={logo} alt="logo" style={{ width: "100%" }} />
      <Divider />
      <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        {navItems.map((item) => (
          <NavLink key={item.label} {...item.props}>
            <Box sx={{ p: 2 }}>
              <Typography>{item.label}</Typography>
            </Box>
          </NavLink>
        ))}
        {user && <Button onClick={logout}>Logout</Button>}
      </Box>
    </Box>
  );

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Toolbar sx={{ display: "flex" }}>
          <Box
            sx={{ flex: 1, cursor: "pointer" }}
            onClick={() => (window.location.href = "/")}
          >
            <img src={logo} alt="logo" style={{ height: "75px" }} />
          </Box>
          <Box
            sx={{ display: { xs: "none", sm: "flex", alignItems: "center" } }}
          >
            {navItems.map((item) => (
              <Box key={item.label} sx={{ mr: 2 }}>
                <NavLink {...item.props}>
                  <Typography sx={{ mr: 2 }}>{item.label}</Typography>
                </NavLink>
              </Box>
            ))}
            {user && <Button onClick={logout}>Logout</Button>}
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Box>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};
