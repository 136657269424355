import { Box } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import api from "utils/ghost-api";
import AllPosts from "./landing/AllPosts";
import Footer from "./landing/Footer";
import GhostPage from "./landing/GhostPage";
import GhostPost from "./landing/GhostPost";
import Home from "./landing/Home";
import Meta from "./landing/Meta";
import Terms from "./landing/Terms";
import { Navbar } from "./Navbar";

import "styles/reset.css";

import "styles/custom.css";

const transform = (node) => {};

const BlogContext = React.createContext({ pages: [] });

const Landing = () => {
  const [pages, setPages] = useState([]);
  const [posts, setPosts] = useState([]);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    api.posts
      .browse({ limit: 5, include: "tags,authors", filter: "featured:true" })
      .then((posts) => {
        setPosts(posts);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    api.pages
      .browse({ include: "tags" })
      .then((pages) => {
        setPages(pages);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    api.settings.browse().then((settings) => {
      setSettings(settings);
    });
  }, []);

  return (
    <Router>
      <Meta settings={settings} />
      <Box
        sx={{
          fontFamily: "body",
        }}
      >
        <BlogContext.Provider value={{ pages }}>
          <Navbar />
          <Routes>
            {pages.map((page) => (
              <Route
                path={`/${page.slug}`}
                key={page.slug}
                element={<GhostPage page={page} />}
              />
            ))}
            <Route path="/post/:slug" element={<GhostPost />} />
            <Route path="/posts" element={<AllPosts />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/" element={<Home posts={posts} />} />
          </Routes>
          <Footer />
        </BlogContext.Provider>
      </Box>
    </Router>
  );
};

export default Landing;
