import { Box, Typography } from "@mui/material";
import * as React from "react";

const postUrl = (post) => `/post/${post.slug}`;

export const postPreview = (post) => {
  if (post.excerpt.length > 120) {
    return post.excerpt.substring(0, 100) + "...";
  }
  return post.excerpt;
};

const PostPreview = ({ post }: any) => {
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          borderRadius: "10px",
          backgroundImage: `url(${post.feature_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          aspectRatio: {
            xs: "2 / 1",
            sm: "1 / 1",
          },
        }}
      />
      <Typography
        sx={{
          my: 2,
          fontWeight: 700,
          fontSize: "1.5em",
          lineHeight: 1.1,
          minHeight: "2em",
        }}
      >
        {post.title}
      </Typography>
      <Typography variant="body2" sx={{ minHeight: "4em", mb: 2 }}>
        {postPreview(post)}
      </Typography>
      <Typography component="a" href={postUrl(post)}>
        Read more
      </Typography>
    </Box>
  );
};

export default PostPreview;
