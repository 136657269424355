import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { theme } from "theme";
import Landing from "./components/Landing";

const root = createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Landing />
    </ThemeProvider>
  </StrictMode>
);
