import { Box, Button, Grid, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import api from "utils/ghost-api";
import PostPreview from "./PostPreview";

const postUrl = (post) => `/post/${post.slug}`;

const AllPosts = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [firstView, setFirstView] = useState(true);
  const [pageMeta, setPageMeta] = useState({
    pagination: {
      pages: 1,
      limit: 0,
    },
  });

  const nextPage = () => {
    setPage(page + 1);
    if (myRef.current) {
      scrollToRef(myRef);
    }
  };

  const previousPage = () => {
    setPage(page - 1);
    if (myRef.current) {
      scrollToRef(myRef);
    }
  };

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);

  useEffect(() => {
    api.posts
      .browse({
        limit: 12,
        page,
        include: "tags,authors",
        filter: "featured:true",
      })
      .then((posts) => {
        setPosts(posts);
        if (!firstView) {
          scrollToRef(myRef);
        } else {
          setFirstView(false);
        }
        setPageMeta(posts.meta);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [page]);

  return (
    <Box>
      <Box
        sx={{
          px: {
            xs: 6,
            sm: 12,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 3,
            justifyContent: "center",
            background: "#f5f5f5",
            borderRadius: "10px",
            alignItems: "center",
            m: {
              xs: 2,
              sm: 4,
            },
            mb: {
              xs: 4,
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              mb: 2,
              textTransform: "uppercase",
              letterSpacing: "0.1em",
            }}
          >
            The Exercise Clinic news
          </Typography>
          <Typography
            sx={{ fontSize: "1.5em", fontWeight: 500, lineHeight: 1.1 }}
          >
            All of our stories from The Exercise Clinic
          </Typography>
        </Box>
        <Grid container spacing={4} sx={{}}>
          {posts.map((post, index) => (
            <Grid item xs={12} sm={6} md={3} key={post.id}>
              <PostPreview post={post} />
            </Grid>
          ))}
        </Grid>
        {pageMeta.pagination.pages > 1 && (
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Box>
              {page > 1 && <Button onClick={previousPage}>Newer posts</Button>}
            </Box>
            <Typography>
              Page {page} of {pageMeta.pagination.pages}
            </Typography>
            <Box>
              <Button onClick={nextPage}>
                {page < pageMeta.pagination.pages && "Older posts"}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AllPosts;
