import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import Link from "components/Link";
import { format } from "date-fns";
import * as React from "react";
import banner from "../banner.png";
import chris from "../chris.jpg";
import emily from "../emily.jpg";
import PostPreview from "./PostPreview";

const postUrl = (post) => `/post/${post.slug}`;

const Banner = () => {
  return (
    <Box
      sx={{
        p: {
          xs: 1,
          sm: 4,
        },
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        spacing={4}
        direction="row-reverse"
        sx={{
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={5}>
          <Box>
            <img src={banner} alt="couple" style={{ width: "100%" }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography
              sx={{ fontSize: "3em", fontWeight: 700, lineHeight: 1.1, mb: 2 }}
            >
              Welcome to
              <br />
              The Exercise Clinic
            </Typography>
            <Typography sx={{ fontSize: "1.5em", lineHeight: 1.5 }}>
              Thank you for visiting our website. Here you’ll find out about our
              work and some of the incredible people who have helped make The
              Exercise Clinic what it is today.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const Home = ({ posts }) => {
  const topPost = posts[0] || {};
  return (
    <Box px={5} py={4}>
      <Banner />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            mb: 2,
            textTransform: "uppercase",
            letterSpacing: "0.1em",
          }}
        >
          Our team
        </Typography>

        <Typography sx={{ fontSize: "1.2em", lineHeight: 1.1, p: 2, mb: 4 }}>
          Emily Curtis and Chris Cottrell founded The Exercise Clinic in 2016.
          We share a passion for exercise and its health benefits for people who
          are living with cancer.
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Grid
            container
            spacing={4}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} sm={3}>
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundImage: `url(${emily})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  aspectRatio: {
                    xs: "1 / 1",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={9} sx={{ p: 2, textAlign: "left" }}>
              <Typography sx={{ fontWeight: 500, fontSize: "1.5em", mb: 1 }}>
                Emily
              </Typography>
              <Typography>
                Over the last ten years, Emily has been working as a Clinical
                Exercise Physiologist registered with Academy of Healthcare
                Science. Emily is also a Clinical Research Fellow at The Royal
                Marsden NHS Foundation Trust and is leading an exercise trial to
                support prostate cancer patients on long-term treatment. You can
                find out more about this trial below.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Grid
            container
            spacing={4}
            direction="row-reverse"
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} sm={3}>
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundImage: `url(${chris})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  aspectRatio: {
                    xs: "1 / 1",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={9} sx={{ p: 2, textAlign: "left" }}>
              <Typography sx={{ fontWeight: 500, fontSize: "1.5em", mb: 1 }}>
                Chris
              </Typography>
              <Typography>
                Chris was diagnosed with aggressive prostate cancer in 2011.
                After initial hormone therapy treatment and surgery, his PSA
                began to rise and he was recommended to start an exercise
                programme. Over the next two years, his PSA progression slowed
                significantly. Since 2016 Chris has gone on to have further
                treatment including chemotherapy, radiotherapy and long-term
                hormone therapy. He continues to exercise and believes that
                resistance and cardiovascular exercise has had a positive impact
                in the management of his disease and the side effects of
                treatment.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
          justifyContent: "center",
          background: "#f5f5f5",
          borderRadius: "10px",
          alignItems: "center",
          m: {
            xs: 2,
            sm: 4,
          },
          mt: {
            xs: 4,
            sm: 4,
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            mb: 2,
            textTransform: "uppercase",
            letterSpacing: "0.1em",
          }}
        >
          The Exercise Clinic news
        </Typography>
        <Typography
          sx={{ fontSize: "1.5em", fontWeight: 500, lineHeight: 1.1 }}
        >
          Stories and updates from Emily and Chris
        </Typography>
      </Box>
      {posts && (
        <Box
          sx={{
            px: {
              xs: 4,
              sm: 8,
            },
          }}
        >
          <Grid container spacing={2} sx={{ mt: 4 }}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box
                  component="img"
                  src={topPost.feature_image}
                  sx={{ borderRadius: "10px", width: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: {
                    xs: 2,
                    sm: 4,
                  },
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",

                      mb: 2,
                    }}
                  >
                    <Avatar
                      src={topPost?.primary_author?.profile_image}
                      sx={{ width: 32, height: 32, mr: 2 }}
                    />
                    <Typography>{topPost?.primary_author?.name}</Typography>
                    {topPost.published_at && (
                      <Typography sx={{ ml: 2 }}>
                        {format(
                          Date.parse(topPost.published_at),
                          "do MMMM yyyy"
                        )}
                      </Typography>
                    )}
                  </Box>
                  <Typography
                    sx={{ fontWeight: 700, fontSize: "1.5em", lineHeight: 1.1 }}
                  >
                    {topPost.title}
                  </Typography>
                  <Typography sx={{ mt: 2 }}>{topPost.excerpt}</Typography>
                </Box>
                {topPost.tags && (
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      mb: 2,
                      color: "primary.main",
                    }}
                  >
                    {topPost.tags[0]?.name.toUpperCase()}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              sx={{ fontWeight: 500, fontSize: "1.5em", mt: 4, mb: 2, flex: 1 }}
            >
              The latest
            </Typography>
            <Typography>
              <Link
                to="/posts"
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#000000",
                  textDecoration: "underline",
                  textUnderlineOffset: 8,
                  textDecorationThickness: 2,
                  textDecorationColor: "#c83881",
                }}
              >
                View all <ArrowForwardIcon sx={{ ml: 0.5 }} />
              </Link>
            </Typography>
          </Box>
          <Grid container spacing={4} sx={{}}>
            {posts.slice(1, 5).map((post, index) => (
              <Grid item xs={12} sm={6} md={3} key={post.id}>
                <PostPreview post={post} key={index} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Home;
