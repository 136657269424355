import { Box, Typography } from "@mui/material";
import parse from "html-react-parser";
import * as React from "react";
import { useEffect, useState } from "react";
import api from "utils/ghost-api";

const Terms = (props) => {
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    api.pages
      .read({ slug: "terms-and-conditions" })
      .then((page) => setTerms(page))
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Box
      sx={{
        p: 10,
        fontFamily: '"Poppins", sans-serif',
        "& p": {
          my: 4,
          lineHeight: 1.4,
        },
        "& ul": {
          ml: 4,
          listStyleType: "circle",
        },
        "& strong": {
          fontWeight: 500,
        },
        "& li": {
          my: 2,
        },
        "& blockquote": {
          m: 2,
          lineHeight: 1.25,
          fontStyle: "italic",
          fontWeight: 200,
        },
      }}
    >
      <Typography variant="h4" sx={{ mb: 4 }}>
        Our Privacy Policy
      </Typography>
      {terms && parse(terms?.html)}
    </Box>
  );
};

export default Terms;
