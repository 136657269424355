import { Avatar, Box, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import parse, { attributesToProps } from "html-react-parser";
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "utils/ghost-api";

const ByLine = ({ author, date }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Avatar sx={{ width: 50, height: 50, mr: 2 }} src={author.profile_image} />
    <Box>
      <Typography sx={{ fontWeight: 600 }}>{author.name}</Typography>
      <Box>
        <Typography>{format(parseISO(date), "do MMMM yyyy")}</Typography>
        <Typography variant="caption">1 min read</Typography>
      </Box>
    </Box>
  </Box>
);

const GhostPost = () => {
  let { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    api.posts
      .read({ slug, include: "tags,authors" })
      .then((post) => setPost(post))
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      {post && (
        <Box
          sx={{
            px: {
              xs: 6,
              sm: 12,
            },
            fontFamily: '"Poppins", sans-serif',
            "& p": {
              lineHeight: 1.4,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "60%",
                aspectRatio: "4 / 3",
                borderRadius: "10px",
                backgroundImage: `url(${post.feature_image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Box>
          <Typography sx={{ my: 3 }} variant="h4">
            {post.title}
          </Typography>
          <ByLine author={post.primary_author} date={post.published_at} />
          <Box
            sx={{
              mt: 4,
              maxWidth: "600px",
              "& p": {
                my: 2,
                lineHeight: 1.25,
              },
              "& ul": {
                ml: 4,
                listStyleType: "circle",
              },
              "& li": {
                my: 1,
              },
              "& blockquote": {
                m: 2,
                lineHeight: 1.25,
                fontStyle: "italic",
                fontWeight: 200,
              },
            }}
          >
            {parse(post?.html, {
              replace(domNode) {
                if ((domNode as any)?.name == "img") {
                  const { height, width, ...otherAttribs } = (domNode as any)
                    ?.attribs;
                  const props = attributesToProps(otherAttribs);
                  return <img {...props} />;
                }
              },
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default GhostPost;
