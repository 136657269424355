import { Box, Typography } from "@mui/material";
import parse from "html-react-parser";
import * as React from "react";

const GhostPage = ({ page }) => (
  <Box>
    <Box>
      <Typography>{page.title}</Typography>
    </Box>
    <Box p={3} width={["100%", "70%", "50%"]} color="black">
      {parse(page?.html)}
    </Box>
  </Box>
);

export default GhostPage;
