import { Box, Typography } from "@mui/material";
import Link from "components/Link";
import * as React from "react";

const Footer = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        p: 4,
        boxSizing: "border-box",
        textAlign: "center",
        mt: 2,
      }}
    >
      <Typography>
        &copy; 2025 The Exercise Clinic. See our privacy policy{" "}
        <Link to={"/terms"}>here</Link>.
      </Typography>
    </Box>
  );
};

export default Footer;
